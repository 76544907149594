<template lang="pug">
  //- kerning "ol" "ia":
    <svg viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
      <g fill="currentColor">
        <path d="M0.7 3.6H0V2.3H0.7V1.3C0.7 0.7 1.1 0 2 0C2.6 0 2.9 0.1 3 0.1V1.4C2.9 1.4 2.8 1.4 2.6 1.4C2.3 1.4 2 1.5 2 1.8V2.3H2.8V3.6H2V8.3H0.7V3.6Z"/>
        <path d="M6.3 2.2C8.1 2.2 9.2 3.7 9.2 5.4C9.2 6.9 8.2 8.5 6.3 8.5C4.4 8.5 3.4 6.8 3.4 5.3C3.4 3.7 4.5 2.2 6.3 2.2ZM6.3 3.5C5.4 3.5 4.7 4.2 4.7 5.3C4.7 6.5 5.3 7.1 6.3 7.1C7.2 7.1 7.9 6.5 7.9 5.3C7.9 4.2 7.3 3.5 6.3 3.5Z"/>
        <path d="M11.5 0.100006V8.30001H10.1V0.100006H11.5Z"/>
        <path d="M14.1 0V1.4H12.7V0H14.1ZM14.1 2.3V8.3H12.7V2.3H14.1V2.3Z"/>
        <path d="M21 7C21.2 7 21.3 7 21.5 6.9V8.2C21.1 8.3 20.9 8.3 20.6 8.3C19.9 8.3 19.5 8.1 19.2 7.7C19 7.9 18.4 8.4 17.3 8.4C16 8.4 14.9 7.5 14.9 6.4C14.9 5 16.1 4.5 17.7 4.5H19V4.2C19 3.6 18.5 3.1 17.8 3.1C17.1 3.1 16.7 3.5 16.7 4.2H15.2C15.2 2.9 16.4 2.2 17.8 2.2C19.2 2.2 20.3 2.8 20.3 4.1V6.5C20.4 6.9 20.6 7 21 7ZM17.6 7.4C18.6 7.4 19 6.6 19 6.1V5.5C16.9 5.4 16.5 5.9 16.5 6.5C16.6 7 17 7.4 17.6 7.4Z"/>
      </g>
    </svg>
  //- 0 -15, a -5:
  <svg viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
    <g fill="currentColor">
      <path d="M0.7 3.6H0V2.3H0.7V1.3C0.7 0.7 1.1 0 2 0C2.6 0 2.9 0.1 3 0.1V1.4C2.9 1.4 2.8 1.4 2.6 1.4C2.3 1.4 2 1.5 2 1.8V2.3H2.8V3.6H2V8.3H0.7V3.6Z"/>
      <path d="M6.29999 2.2C8.09999 2.2 9.19999 3.7 9.19999 5.4C9.19999 6.9 8.19999 8.5 6.29999 8.5C4.39999 8.5 3.39999 6.8 3.39999 5.3C3.39999 3.7 4.49999 2.2 6.29999 2.2ZM6.29999 3.5C5.39999 3.5 4.69999 4.2 4.69999 5.3C4.69999 6.5 5.29999 7.1 6.29999 7.1C7.19999 7.1 7.89999 6.5 7.89999 5.3C7.89999 4.2 7.29999 3.5 6.29999 3.5Z"/>
      <path d="M11.6 0.100006V8.30001H10.2V0.100006H11.6Z"/>
      <path d="M14.2 0V1.4H12.8V0H14.2ZM14.2 2.3V8.3H12.8V2.3H14.2V2.3Z"/>
      <path d="M21.2 7C21.4 7 21.5 7 21.7 6.9V8.2C21.3 8.3 21.1 8.3 20.8 8.3C20.1 8.3 19.7 8.1 19.4 7.7C19.2 7.9 18.6 8.4 17.5 8.4C16.2 8.4 15.1 7.5 15.1 6.4C15.1 5 16.3 4.5 17.9 4.5H19.1V4.2C19.1 3.6 18.6 3.1 17.9 3.1C17.2 3.1 16.8 3.5 16.8 4.2H15.3C15.3 2.9 16.5 2.2 17.9 2.2C19.3 2.2 20.4 2.8 20.4 4.1V6.5C20.5 6.9 20.8 7 21.2 7ZM17.8 7.4C18.8 7.4 19.2 6.6 19.2 6.1V5.5C17.1 5.4 16.7 5.9 16.7 6.5C16.8 7 17.2 7.4 17.8 7.4Z"/>
    </g>
  </svg>

  //- kerning before "a":
    <svg viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
      <g fill="currentColor">
        <path d="M0.7 3.6H0V2.3H0.7V1.3C0.7 0.7 1.1 0 2 0C2.6 0 2.9 0.1 3 0.1V1.4C2.9 1.4 2.8 1.4 2.6 1.4C2.3 1.4 2 1.5 2 1.8V2.3H2.8V3.6H2V8.3H0.7V3.6Z"/>
        <path d="M6.3 2.2C8.1 2.2 9.2 3.7 9.2 5.4C9.2 6.9 8.2 8.5 6.3 8.5C4.4 8.5 3.4 6.8 3.4 5.3C3.4 3.7 4.5 2.2 6.3 2.2ZM6.3 3.5C5.4 3.5 4.7 4.2 4.7 5.3C4.7 6.5 5.3 7.1 6.3 7.1C7.2 7.1 7.9 6.5 7.9 5.3C7.9 4.2 7.3 3.5 6.3 3.5Z"/>
        <path d="M11.7 0.100006V8.30001H10.3V0.100006H11.7Z"/>
        <path d="M14.3 0V1.4H13V0H14.3ZM14.3 2.3V8.3H13V2.3H14.3V2.3Z"/>
        <path d="M21.2 7C21.4 7 21.5 7 21.7 6.9V8.2C21.3 8.3 21.1 8.3 20.8 8.3C20.1 8.3 19.7 8.1 19.4 7.7C19.2 7.9 18.6 8.4 17.5 8.4C16.2 8.4 15.1 7.5 15.1 6.4C15.1 5 16.3 4.5 17.9 4.5H19.1V4.2C19.1 3.6 18.6 3.1 17.9 3.1C17.2 3.1 16.8 3.5 16.8 4.2H15.3C15.3 2.9 16.5 2.2 17.9 2.2C19.3 2.2 20.4 2.8 20.4 4.1V6.5C20.6 6.9 20.8 7 21.2 7ZM17.9 7.4C18.9 7.4 19.3 6.6 19.3 6.1V5.5C17.2 5.4 16.8 5.9 16.8 6.5C16.8 7 17.2 7.4 17.9 7.4Z"/>
      </g>
    </svg>

  //- no kerning
    <svg viewBox="0 0 23 9" fill="none" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio>
      <g fill="currentColor">
        <path d="M0.7 3.6H0V2.3H0.7V1.3C0.7 0.7 1.1 0 2 0C2.6 0 2.9 0.1 3 0.1V1.4C2.9 1.4 2.8 1.4 2.6 1.4C2.3 1.4 2 1.5 2 1.8V2.3H2.8V3.6H2V8.3H0.7V3.6Z"/>
        <path d="M6.3 2.2C8.1 2.2 9.2 3.7 9.2 5.4C9.2 6.9 8.2 8.5 6.3 8.5C4.4 8.5 3.4 6.8 3.4 5.3C3.4 3.7 4.5 2.2 6.3 2.2ZM6.3 3.5C5.4 3.5 4.7 4.2 4.7 5.3C4.7 6.5 5.3 7.1 6.3 7.1C7.2 7.1 7.9 6.5 7.9 5.3C7.9 4.2 7.3 3.5 6.3 3.5Z"/>
        <path d="M11.8 0.100006V8.30001H10.4V0.100006H11.8Z"/>
        <path d="M14.6 0V1.4H13.2V0H14.6ZM14.6 2.3V8.3H13.2V2.3H14.6V2.3Z"/>
        <path d="M21.6 7C21.8 7 21.9 7 22.1 6.9V8.2C21.7 8.3 21.5 8.3 21.2 8.3C20.5 8.3 20.1 8.1 19.8 7.7C19.6 7.9 19 8.4 17.9 8.4C16.6 8.4 15.5 7.5 15.5 6.4C15.5 5 16.7 4.5 18.3 4.5H19.5V4.2C19.5 3.6 19 3.1 18.3 3.1C17.6 3.1 17.2 3.5 17.2 4.2H15.7C15.7 2.9 16.9 2.2 18.3 2.2C19.7 2.2 20.8 2.8 20.8 4.1V6.5C21 6.9 21.2 7 21.6 7ZM18.2 7.4C19.2 7.4 19.6 6.6 19.6 6.1V5.5C17.5 5.4 17.1 5.9 17.1 6.5C17.2 7 17.6 7.4 18.2 7.4Z"/>
      </g>
    </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style>
</style>
